@import url("https://fonts.googleapis.com/css2?family=Inspiration&family=Kaushan+Script&family=Orbitron:wght@400;500&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/conthrax");

@tailwind base;
@tailwind components;
@tailwind utilities;


.l-mode {
  --tx-1: #6c6f73;
  --tx-2: #55565a;
  --tx-3: #7d8398;
  --tx-4: #fcfcfc;
  --tx-5: #64758B;
  --tx-6:#334155;
  --tx-7:#000000;
  --sh-1:#22222280;
  --gr-0: #F9FAFB;
  --gr--0: #F9FAFB;
  --gr--3: #E0E0E0;
  --gr-1:#bbc2c7;
  --gr-2: #edf2f7;
  --gr-3: #E0E0E0;
  --gr-4: #6B7280;
  --gr-5: #6B7280;
  --gr-6:#4A5568 ;
  --gr-7: #4a5568;
  --gr-9: #1A202C;
  --gr-10: #a3aeb9;

  --bg-1: #fff;
  --bg-2: #cccccc;
  --bg-3: #e5e5e5;

  --btn-txt: #ffffff;

  --primary: #656ED3; /* #3B82F6*/
  --primary-hover: #656ed3db;

  --nav-bg-1: #e2e8f0;
  --nav-bg-2: #fafafa;

  --decor-1: #afb3ff;
  --decor-2: #656ED3;
  --oposite: #000000
}

.d-mode {
  --tx-1: #c9cacd;
  --tx-2: #dcdcde;
  --tx-3: #a8b2c1;
  --tx-4: #1a1a1a;
  --tx-5: #a8b2c1;
  --tx-6: #718096;
  --tx-7:#ffffff;

  --sh-1:#FFFFFF80;

  --gr--0: #1b1f22;
  --gr--3: #374151;
  --gr-0: #1A202C;
  --gr-1: #2D3748;
  --gr-2: #2d3748;
  --gr-3: #424242;
  --gr-4: #383C45;
  --gr-5: #D1D5DB;
  --gr-6:#CBD5E0;
  --gr-7: #a0aec0;
  --gr-9: #E2E8F0;
  --gr-10: #2d3850;

  --bg-1: #1e1e1e;
  --bg-2:#4a4a4a;
  --bg-3: #888888;

  --btn-txt: #1a1a1a;
 /*  --primary: #14b8a6;
  --primary-hover: #0f766e; */
  --primary: #656ED3; /* #3B82F6*/
  --primary-hover: #656ed3db;

  --nav-bg-1: #3a3e4b;
  --nav-bg-2: #212224;

  --decor-1: #6e71b9;
  --decor-2: #656ED3;
  --oposite: #ffffff;
}

.body_overflow_hidden {
  overflow: hidden !important;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", "sans-serif";
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  scrollbar-width: .25rem;
}
/* CUSTOM SCROLL BAR */
::-webkit-scrollbar {
  width: .25rem;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.full_height-footer {
  min-height: calc(100dvh - 7rem);
}

body {
  background: var(--bg-1);
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


section {
  padding: 0 4%;
  width: 100%;
  overflow: hidden;
}

.bg {
  background: linear-gradient(to bottom, #f6f6f6, #fcfcfc);
}


@keyframes fadeInOutAnimation {
  0%,
  100% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }
}

.fade {
  animation: fadeInOutAnimation 1.5s ease-in-out infinite;
}

.Poppins {
  font-family: "Poppins", sans-serif;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate_360 {
  animation: rotate360 .7s linear;
}

