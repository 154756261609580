.sticky-column {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0
}
              
.container {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
}
